var Slick = require('./plugins/slick.js');

jQuery(document).ready(function ($) {

    $('.header__toggle').on('click', function (e) {
        e.preventDefault();
        $('.header').toggleClass('header--mobile');
    });

    $('.proefles-modal, .modal__close').on('click', function (e) {
        e.preventDefault();
        $('.modal').toggleClass('open');
    });

    $(".dropdown").on({
        mouseenter: function () {
            $('.sub-menu').addClass('open');
        },
        mouseleave: function () {
            $('.sub-menu').removeClass('open');
        }
    });

    $('.block--tabs ul li a').on('click', function (e) {
        e.preventDefault();
        $('.block--tabs ul li a').removeClass('active');
        $(this).addClass('active');
        $('.group').removeClass('group-show');
        $('.group').hide();
        // console.log($(this).data('name'));
        $('.group[data-id="' + $(this).data('name') +'"]').show();
    });
    
    if($('.slider--insta')[0]) {
        
        $('.slider--insta').slick({
            dots: true,
            infinite: false,
            speed: 300,
            arrows: false,
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            rows: 0,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }

    if($('.slider--reviews')[0]) {
        
        $('.slider--reviews').slick({
            dots: true,
            infinite: false,
            speed: 300,
            arrows: true,
            dots: false,
            slidesToShow: 1.6,
            slidesToScroll: 1,
            prevArrow: $('.left'),
            nextArrow: $('.right'),
            rows: 0,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1.6,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }
    
    if($('.ticker')[0]) {
        gsap.set(".ticker ul", {xPercent: -50})
        
        gsap.to(".ticker ul", {
            xPercent: 50,
            scrollTrigger: {
                scrub: true,
                start: "top bottom",
                end: "bottom top",
                markers: false
            }
        });
    }
});